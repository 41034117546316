import React, { useState, useEffect } from "react"
import toast from "react-hot-toast"
import { navigate } from 'gatsby-link'
import { useLocation } from '@reach/router'

import Layout from "../../components/layout/layout"
import Meta from "../../components/addon/meta"
import Loader from "../../components/addon/Loader"

import { getCookie } from "../../service/helper"
import DOCTOR_API from '../../api/doctors'
import APIButton from "../../components/addon/button/apiButton"

const TableRow = (props) => {
    const { hhNumber, el } = props
    
    const [btnLoading, setBtnLoading] = useState(false)

    const downloadBill = (blob, orderNo) => {
        const linkSource = `data:application/pdf;base64,${blob}`;
        const downloadLink = document.createElement("a");
        const fileName = `HH-medical-report-${orderNo}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const dowmloadOnlineReport = (orderNo, accessioN_NO) => {
        console.log('asasasasas===>>>',hhNumber, orderNo, accessioN_NO)
        setBtnLoading(true)
        let payload = {
            hhNumber: hhNumber,
            "type":"HH",
            orderNumber: orderNo,
            accessioN_NO: accessioN_NO
        }
        DOCTOR_API.getMedicalReportDetails(payload)
        .then(res => {
            if(res?.success && res?.data?.length) {
                downloadBill(res?.data?.[0], orderNo)
            }
            setBtnLoading(false)
        })
        .catch(err => {
            console.log(err)
            setBtnLoading(false)
        })
    }

    return (
        <tr>
            <td>{el?.ordeR_NUMBER}</td>
            <td>{el?.tesT_DATE}</td>
            <td>{el?.tesT_NAME}</td>
            <td>{el?.reporT_STATUS === 'Y' ? 'Ready' : 'Not Ready'}</td>
            <td>
                <APIButton title={`VIEW / DOWNLOAD`} 
                    loading={btnLoading} 
                    disabled={btnLoading} 
                    onBtnClick={() => dowmloadOnlineReport(el?.ordeR_NUMBER, el?.accessioN_NO)}  
                    className={`btn btn-primary`} 
                />
                {/* <a href="javascript:void(0);" class="btn btn-primary"  >VIEW / DOWNLOAD</a> */}
            </td>
        </tr> 
    )
}

const MedicalReportsDetail = () => {
    const metaTags = []

    const location = useLocation()
    const [hhNumber, setHHNumber] = useState(null)
    const [regType, setRegType] = useState(null)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getOnlineReports = () => {
        setLoading(true)
        let payload = {
            patType:regType,
            hhNumber: hhNumber
        }
        DOCTOR_API.getAllMedicalReports(payload)
        .then(res => {
            if(res?.data) {
                setData(res.data)
            } else if(res?.success === false) {
                toast.error(res?.message ? res.message : 'Something went wrong')
            }
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        if(hhNumber?.length) getOnlineReports()
    },[hhNumber])

    useEffect(() => {
        if(location && location?.state?.hhNumber && location && location?.state?.regType) {
            setHHNumber(location?.state?.hhNumber)
            setRegType(location?.state?.regType)
        } else if (getCookie('hh')) {
            setHHNumber(getCookie('hh'))
        } else {
            navigate(`/medical-report`)
        }
    }, [location, getCookie('hh')])

    return (
    <Layout>
        <Meta
            files={{
                js: [],
                css: ["/assets/css/services.css", "/assets/css/accessibility.css"],
            }}
            tags={metaTags}
        />
        <main className="innerpage">
            <section class="section_bg login_section pt-first-section">
                <div class="container">
                    <h2 class="section-heading">Medical Reports Portal</h2>
                    <div class="row no-gutters onlineReportTableWrap">
                        <div class="col-lg-12 col-md-12">
                            <div class="white_rounded_box mb-4">
                                <div class="form_wrap">
                                    <p>Click on the donwload button to view the report.</p>
                                    <div class="table_wrap mt-4">
                                        <table class="table table-bordered table-responsive d-inline-table">
                                            <thead>
                                                <tr>
                                                    <th>Order Number</th>
                                                    <th>Investigation Date</th>
                                                    <th>Investigation Name</th>
                                                    <th>Report Status</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.length && !loading 
                                                ?  data?.map(el => {
                                                    return (
                                                        <TableRow key={el?.ordeR_NUMBER} hhNumber={hhNumber} el={el} />
                                                    )
                                                })
                                                : null}
                                            </tbody>
                                        </table>
                                        {!data?.length && !loading 
                                        ?  <p className="">No Data Found.</p>
                                        : null}
                                        {loading && <Loader loadingMessage="Loading data..." />}
                                    </div>
                                </div>
                            </div>
                            <p>* Reports are available for 30 days only.</p>
                            <p>You must have a current PDF reader to view Reports. If you do not see the Reports, please <a href="https://get.adobe.com/reader/" target="_blank">click here</a> to download PDF reader software.</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </Layout>
    )
}
export default MedicalReportsDetail